<template>
  <body class="e-wallet-landing">

    <!--====== Start Header ======-->
    <header class="template-header navbar-center absolute-header bordered-header sticky-header">
      <div class="container-fluid container-1430">
        <div class="header-inner">
          <div class="header-left">
            <div class="branding-and-language-selection">
              <div class="brand-logo">
                <a href="#">
                  <b-img
                    src="@/assets/images/logo/logo-white.png"
                    alt="logo"
                  />
                </a>
              </div>
              <!-- <div class="language-selection d-none d-md-block">
                            <select name="language">
                                <option value="en">繁體中文</option>
                                <option value="ts">簡體中文</option>
                                <option value="en">英文</option>
                            </select>
                        </div> -->
            </div>
          </div>
          <div class="header-center">
            <nav class="nav-menu d-none d-xl-block">
              <ul>
                <li class="active">
                  <a href="#">首頁</a>
                </li>
                <li>
                  <a href="#service-tab-section">功能</a>
                </li>
                <li>
                  <a href="#pricing-section">價格</a>
                </li>
                <li>
                  <a href="/links">聯絡我們</a>
                </li>
              </ul>
            </nav>
          </div>
          <div class="header-right">
            <ul class="header-extra">
              <li class="d-none d-sm-block">
                <a
                  href="/login"
                  class="user-login user-login-border-right text-white"
                >
                  <feather-icon
                    icon="LogInIcon"
                    class="text-primary mr-2"
                    stroke-width="4"
                    size="16"
                  /> 登入
                </a>
              </li>
              <li class="d-none d-lg-block">
                <a
                  href="/signup"
                  class="template-btn"
                >
                  註冊<i class="fas fa-arrow-right" />
                </a>
              </li>
              <li class="d-xl-none">
                <a
                  v-b-toggle.sidebar-right
                  class="navbar-toggler"
                >
                  <span />
                  <span />
                  <span />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <!-- Start Mobile Slide Menu -->
      <b-sidebar
        id="sidebar-right"
        bg-variant="white"
        right
        backdrop
        shadow
      >
        <div>
          <div class="text-center">
            <!-- Avatar -->
            <a href="/">
              <b-img
                src="@/assets/images/logo/logo-white.png"
                alt="logo"
              />
            </a>
          </div>

          <b-list-group class="mt-3 rounded-0">
            <b-list-group-item
              v-for="item in sidebarItems"
              :key="item.title"
              class="bg-transparent"
            >
              <!-- <feather-icon
                class="align-text-bottom"
                :icon="item.icon"
              /> -->
              <a
                :href="item.url"
              >
                <span
                  class="ml-1"
                  v-text="item.title"
                />
              </a>
            </b-list-group-item>
          </b-list-group>
        </div>
      </b-sidebar>
      <!-- <div class="mobile-slide-panel">
        <div class="panel-overlay" />
        <div class="panel-inner">
          <div class="mobile-logo">
            <a href="index.html">
              <b-img
                src="@/assets/images/logo/logo-white.png"
                alt="logo"
              />
            </a>
          </div>
          <nav class="mobile-menu">
            <ul>
              <li class="active">
                <a href="#">首頁</a>
              </li>
              <li>
                <a href="#service-tab-section">功能</a>
              </li>
              <li>
                <a href="#pricing-section">價格</a>
              </li>
              <li>
                <a href="/links">聯絡我們</a>
              </li>
            </ul>
          </nav>
          <a
            href="#"
            class="panel-close"
          >
            <i class="fal fa-times" />
          </a>
        </div>
      </div> -->
      <!-- End Mobile Slide Menu -->
    </header>
    <!--====== End Header ======-->

    <!-- Start Search From -->
    <div
      id="search-modal"
      class="modal fade search-area"
      tabindex="-1"
      role="dialog"
    >
      <div
        class="modal-dialog"
        role="document"
      >
        <div class="modal-content">
          <form>
            <input
              type="text"
              placeholder="Search here..."
            >
            <button class="search-btn">
              <i class="fa fa-search" />
            </button>
          </form>
        </div>
      </div>
    </div>
    <!-- End Search From -->

    <!--====== Start Hero Area ======-->
    <section class="hero-area-v1">
      <div class="container mt-5">
        <div class="row justify-content-center">
          <div class="col-lg-10 col-xl-11">
            <div class="hero-content">
              <span
                class="title-tag wow fadeInUp"
                data-wow-delay="0.2s"
              >
                <span>省下 25% 的時間</span><span>應收帳款一目了然</span>
              </span>
              <h1
                class="hero-title wow fadeInUp"
                data-wow-delay="0.3s"
              >
                輕鬆管理<span>借貸</span>及<span>顧客</span>資料
              </h1>
              <ul class="hero-btns d-flex justify-content-center">
                <li
                  class="wow fadeInUp"
                  data-wow-delay="0.4s"
                >
                  <a
                    href="/signup"
                    class="template-btn"
                  >
                    馬上建立帳號 <feather-icon
                      icon="ArrowRightIcon"
                      class="ml-2"
                      stroke-width="4"
                      size="16"
                    />
                  </a>
                </li>
                <li
                  class="wow fadeInUp"
                  data-wow-delay="0.4s"
                >
                  <a
                    href="/links"
                    class="template-btn bordered-btn"
                  >
                    聯絡客服 <feather-icon
                      icon="ArrowRightIcon"
                      class="ml-2"
                      stroke-width="4"
                      size="16"
                    />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="hero-particle-effect d-none d-md-block">
          <b-img
            class="particle-1 animate-zoom-fade"
            src="@/assets/images/particle/particle-1.png"
            alt="particle One"
          />
          <b-img
            class="particle-2 animate-float-bob-y"
            src="@/assets/images/particle/particle-2.png"
            alt="particle Two"
          />
          <b-img
            class="particle-3 animate-zoominout"
            src="@/assets/images/particle/particle-3.png"
            alt="particle Three"
          />
          <b-img
            class="particle-4 animate-zoominout"
            src="@/assets/images/particle/particle-4.png"
            alt="particle Four"
          />
          <b-img
            class="particle-5 animate-zoominout"
            src="@/assets/images/particle/particle-5.png"
            alt="particle Five"
          />
        </div>
      </div>
      <div class="container-fluid">
        <div class="hero-img text-center animate-float-bob-y">
          <b-img
            src="@/assets/images/hero/app-dashboard.jpg"
            alt="app-dashboard"
          />
        </div>
      </div>
    </section>
    <!--====== End Hero Area ======-->

    <!--====== Start Benefit Section ======-->
    <section class="benefit-section p-t-125">
      <div class="e-wallet-boxed-container">
        <div class="container">
          <div class="row align-items-center justify-content-lg-between justify-content-center">
            <div class="col-lg-5 col-md-8">
              <div class="benefit-content">
                <div class="common-heading m-b-45">
                  <span class="color-primary-6">BEE</span><span class="text-white">SUMMER</span> <span class="color-primary-6">夏</span><span class="text-white">蜂</span>
                  <h2 class="title">
                    軟體優勢
                  </h2>
                </div>
                <ul
                  class="check-list-3 wow fadeInUp"
                  data-wow-delay="0.2s"
                >
                  <li>
                    <h4 class="title">
                      簡單容易使用
                    </h4>
                    <p>簡單明瞭的設計，更容易上手。</p>
                  </li>
                  <li>
                    <h4 class="title">
                      雲端安全
                    </h4>
                    <p>日本伺服器，連線加密，資料安全有保障。</p>
                  </li>
                  <li>
                    <h4 class="title">
                      帳號權限管理
                    </h4>
                    <p>紀錄所有帳號操作行為，並提供帳號權限管理。</p>
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-lg-7 col-md-8 m-0 p-0">
              <div class="benefit-preview-images">
                <div class="animate-float-bob-y">
                  <b-img
                    src="@/assets/images/landing/md-staff-logs.png"
                    alt="Image"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!--====== End Benefit Section ======-->

    <!--====== Start Data Analysis Area ======-->
    <section class="data-analysis-section p-t-125">
      <div class="container">
        <div class="row align-items-center justify-content-center">
          <div class="col-lg-7 col-md-10 d-sm-block d-none">
            <!-- Preview Gallery One -->
            <div class="preview-galley-v1 m-b-md-70">
              <b-img
                class="preview-image-1 wow fadeInUp"
                src="@/assets/images/landing/manage-data-1.jpg"
                alt="data analysis One"
              />
              <b-img
                class="preview-image-2 animate-float-bob-x wow fadeInUp"
                data-wow-delay="0.2s"
                src="@/assets/images/landing/manage-data-2.jpg"
                alt="data analysis Two"
              />
              <b-img
                class="preview-image-3 wow fadeInUp"
                data-wow-delay="0.3s"
                src="@/assets/images/landing/manage-data-3.jpg"
                alt="data analysis Three"
              />
              <b-img
                class="preview-image-4 wow fadeInUp"
                data-wow-delay="0.4s"
                src="@/assets/images/landing/manage-data-4.jpg"
                alt="data analysis Four"
              />
              <b-img
                class="preview-image-5 animate-float-bob-y"
                data-wow-delay="0.5s"
                src="@/assets/images/landing/manage-data-5.jpg"
                alt="data analysis Five"
              />
              <b-img
                class="preview-image-6 wow fadeInUp"
                data-wow-delay="0.6s"
                src="@/assets/images/landing/manage-data-6.jpg"
                alt="data analysis Six"
              />
            </div>
          </div>
          <div class="col-lg-5 col-md-8">
            <div class="analysis-text-block p-l-50 p-l-md-0">
              <div class="common-heading tagline-boxed m-b-30">
                <span class="tagline">財務報表及業務業績</span>
                <h2 class="title">
                  收支狀態一手掌握
                </h2>
              </div>
              <p style="color: #d0d2d6;">
                每日業績、帳務及月報表
              </p>
              <ul class="check-list m-t-30">
                <li><feather-icon
                  icon="CheckIcon"
                  class="text-primary mr-3"
                  stroke-width="4"
                  size="16"
                />自動結算月報表</li>
                <li><feather-icon
                  icon="CheckIcon"
                  class="text-primary mr-3"
                  stroke-width="4"
                  size="16"
                />日報表清單</li>
                <li><feather-icon
                  icon="CheckIcon"
                  class="text-primary mr-3"
                  stroke-width="4"
                  size="16"
                />業務銷售業績</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!--====== End Data Analysis Area ======-->

    <!--====== Service Section Start ======-->
    <section class="service-section p-t-125">
      <div class="container">
        <!-- Common Heading -->
        <div class="row justify-content-center">
          <div class="col-lg-6">
            <div class="common-heading tagline-boxed m-b-50 text-center">
              <span class="tagline">資料安全</span>
              <h2 class="title">
                所有資訊都經由加密傳送<br>也可另外選擇使用獨立資料庫
              </h2>
            </div>
          </div>
        </div>

        <!-- Icon Box One -->
        <div class="row justify-content-center row-eq-height fancy-icon-boxes-v1 boxes-white-version">
          <div
            class="col-md-6 col-sm-10 wow fadeInUp match-height"
            data-wow-delay="0.2s"
          >
            <div class="fancy-icon-box m-t-30">
              <div class="box-icon">
                <b-img
                  src="@/assets/images/landing/safety-01.png"
                  alt="service icon one"
                />
              </div>
              <div class="box-content">
                <h4 class="title">
                  中立第三方
                </h4>
                <p>以中立的第三方身份經營此系統，目標提高產業價值，並確保與顧客資料不會有利益衝突，下一步則是以開源為主要方展方向。</p>
              </div>
            </div>
          </div>
          <div
            class="col-md-6 col-sm-10wow match-height"
            data-wow-delay="0.3s"
          >
            <div class="fancy-icon-box m-t-30 match-height">
              <div class="box-icon">
                <b-img
                  src="@/assets/images/landing/safety-02.png"
                  alt="service icon one"
                />
              </div>
              <div class="box-content">
                <h4 class="title">
                  完全響應
                </h4>
                <p>免安裝，響應式介面，使用遊覽器在手機、網頁、平版，微軟、蘋果、安卓系統皆可使用。</p>
              </div>
            </div>
          </div>
          <div
            class="col-md-6 col-sm-10 wow fadeInUp match-height"
            data-wow-delay="0.4s"
          >
            <div class="fancy-icon-box m-t-30">
              <div class="box-icon">
                <b-img
                  src="@/assets/images/landing/safety-03.png"
                  alt="service icon one"
                />
              </div>
              <div class="box-content box">
                <h4 class="title">
                  資安防護
                </h4>
                <p>通訊加密、一鍵刪除所有資料，限制可登入 IP 等等......。</p>
              </div>
            </div>
          </div>
          <div
            class="col-md-6 col-sm-10 wow fadeInUp match-height"
            data-wow-delay="0.5s"
          >
            <div class="fancy-icon-box m-t-30">
              <div class="box-icon">
                <b-img
                  src="@/assets/images/landing/safety-04.png"
                  alt="service icon one"
                />
              </div>
              <div class="box-content box">
                <h4 class="title">
                  多帳號、多店設定
                </h4>
                <p>一個帳號可以在多家店使用，每家店可已有很多業務及管理帳號。</p>
              </div>
            </div>
          </div>
          <div
            class="col-md-6 col-sm-10 wow fadeInUp match-height"
            data-wow-delay="0.6s"
          >
            <div class="fancy-icon-box m-t-30">
              <div class="box-icon">
                <b-img
                  src="@/assets/images/landing/safety-05.png"
                  alt="service icon one"
                />
              </div>
              <div class="box-content box">
                <h4 class="title">
                  權限管理及操作紀錄
                </h4>
                <p>可為每個帳號設立獨立的權限，系統也會記錄帳號在商店上的所有操作行為。</p>
              </div>
            </div>
          </div>
          <div
            class="col-md-6 col-sm-10 wow fadeInUp match-height"
            data-wow-delay="0.7s"
          >
            <div class="fancy-icon-box m-t-30">
              <div class="box-icon">
                <b-img
                  src="@/assets/images/landing/safety-06.png"
                  alt="service icon one"
                />
              </div>
              <div class="box-content box">
                <h4 class="title">
                  客製化合作
                </h4>
                <p>若貴公司有其他放款類型、交易型態或是想建立個人資料庫， 可以依照用戶需求在夏蜂借貸軟體上做客製化合作。</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!--====== Service Section End ======-->

    <!--====== Start Service Section ======-->
    <section
      id="service-tab-section"
      class="service-tab-section p-t-125"
    >
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-6">
            <div class="common-heading tagline-boxed m-b-50 text-center">
              <span class="tagline">功能介紹</span>
              <h2 class="title">
                簡單的功能，更好的選擇
              </h2>
            </div>
          </div>
        </div>
      </div>
      <div class="service-tab">
        <div class="container">
          <div class="service-tab-nav">
            <ul
              id="myTab"
              class="nav nav-tabs service-tab-nav"
              role="tablist"
            >
              <li
                class="nav-item"
                role="presentation"
              >
                <a
                  :class="{'active': tabActive === 'service-tab-img'}"
                  class="nav-link"
                  data-toggle="tab"
                  role="tab"
                  @click="tabImage=tabImageList['service-tab-img']; tabActive='service-tab-img';"
                >
                  <i class="far fa-browser" /> 儀表版
                </a>
              </li>
              <li
                class="nav-item"
                role="presentation"
              >
                <a
                  :class="{'active': tabActive === 'service-tab-img2'}"
                  class="nav-link"
                  data-toggle="tab"
                  role="tab"
                  @click="tabImage=tabImageList['service-tab-img2']; tabActive='service-tab-img2';"
                >
                  <i class="far fa-bullseye-arrow" /> 顧客交易資料
                </a>
              </li>
              <li
                class="nav-item"
                role="presentation"
              >
                <a
                  :class="{'active': tabActive === 'service-tab-img3'}"
                  class="nav-link"
                  data-toggle="tab"
                  role="tab"
                  @click="tabImage=tabImageList['service-tab-img3']; tabActive='service-tab-img3';"
                >
                  <i class="far fa-coins" /> 顧客個人資訊
                </a>
              </li>
              <li
                class="nav-item"
                role="presentation"
              >
                <a
                  :class="{'active': tabActive === 'service-tab-img4'}"
                  class="nav-link"
                  data-toggle="tab"
                  role="tab"
                  @click="tabImage=tabImageList['service-tab-img4']; tabActive='service-tab-img4';"
                >
                  <i class="far fa-coins" /> 交易記錄
                </a>
              </li>
              <li
                class="nav-item"
                role="presentation"
              >
                <a
                  :class="{'active': tabActive === 'service-tab-img5'}"
                  class="nav-link"
                  data-toggle="tab"
                  role="tab"
                  @click="tabImage=tabImageList['service-tab-img5']; tabActive='service-tab-img5';"
                >
                  <i class="far fa-book" /> 業務業績
                </a>
              </li>
              <li
                class="nav-item"
                role="presentation"
              >
                <a
                  :class="{'active': tabActive === 'service-tab-img6'}"
                  class="nav-link"
                  data-toggle="tab"
                  role="tab"
                  @click="tabImage=tabImageList['service-tab-img6']; tabActive='service-tab-img6';"
                >
                  <i class="far fa-user-tag" /> 權限管理
                </a>
              </li>
              <li
                class="nav-item"
                role="presentation"
              >
                <a
                  :class="{active: tabActive === 'service-tab-img7'}"
                  class="nav-link"
                  data-toggle="tab"
                  role="tab"
                  @click="tabImage=tabImageList['service-tab-img7']; tabActive='service-tab-img7';"
                >
                  <i class="far fa-money-check-alt" /> 借款
                </a>
              </li>
              <li
                class="nav-item"
                role="presentation"
              >
                <a
                  :class="{'active': tabActive === 'service-tab-img8'}"
                  class="nav-link"
                  data-toggle="tab"
                  role="tab"
                  @click="tabImage=tabImageList['service-tab-img8']; tabActive='service-tab-img8';"
                >
                  <i class="far fa-money-bill-wave-alt" /> 還款
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div class="service-tab-content container-1380 container-fluid">
          <div
            id="myTabContent"
            class="tab-content"
          >
            <div
              class="tab-pane fade show active"
              role="tabpanel"
            >
              <div class="service-tab-ime text-center">
                <b-img
                  :src="tabImage"
                  alt="Image"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!--====== End Service Section ======-->

    <!--====== Pricing Area Start ======-->
    <section
      id="pricing-section"
      class="pricing-section p-t-125"
    >
      <div class="container">
        <div class="common-heading text-center m-b-40">
          <div class="common-heading tagline-boxed m-b-50 text-center">
            <span class="tagline">價格方案</span>
            <h2 class="title">
              月費價格，半年繳及年繳優惠
            </h2>
          </div>
        </div>
        <div class="row pricing-boxes justify-content-center">
          <div
            class="col-lg-4 col-md-6 col-sm-10 wow fadeInUp"
            data-wow-delay="0.2s"
          >
            <div class="pricing-table-three m-t-30">
              <h6 class="plan-name">
                基本方案
              </h6>
              <div class="price">
                <span class="currency">$</span>
                <span>6,500</span>
              </div>
              <span class="plan-subtitle">年繳優惠 5,850/月</span>
              <ul class="plan-feature">
                <li><feather-icon
                  icon="CheckIcon"
                  class="text-primary mr-3"
                  stroke-width="4"
                  size="16"
                /> 現金貸款</li>
                <li><feather-icon
                  icon="CheckIcon"
                  class="text-primary mr-3"
                  stroke-width="4"
                  size="16"
                /> 顧客管理系統</li>
                <li><feather-icon
                  icon="CheckIcon"
                  class="text-primary mr-3"
                  stroke-width="4"
                  size="16"
                /> 店舖經營分析系統</li>
                <li><feather-icon
                  icon="CheckIcon"
                  class="text-primary mr-3"
                  stroke-width="4"
                  size="16"
                /> 軟體資訊安全</li>
                <li><feather-icon
                  icon="CheckIcon"
                  class="text-primary mr-3"
                  stroke-width="4"
                  size="16"
                /> 允許或限制IP位置範圍</li>
                <li><feather-icon
                  icon="CheckIcon"
                  class="text-primary mr-3"
                  stroke-width="4"
                  size="16"
                /> 單間店</li>
                <li class="hidden-feature">
                  <feather-icon
                    icon="CheckIcon"
                    class="text-grey mr-3"
                    stroke-width="4"
                    size="16"
                  /> 擔保品貸款
                </li>
              </ul>
              <!-- <a href="#" class="pricing-btn">Select Plan <i class="fas fa-arrow-right"></i></a> -->
            </div>
          </div>
          <div
            class="col-lg-4 col-md-6 col-sm-10 wow fadeInUp"
            data-wow-delay="0.3s"
          >
            <div class="pricing-table-three featured-plan m-t-30">
              <h6 class="plan-name">
                進階方案
              </h6>
              <div class="price">
                <span class="currency">$</span>
                <span>7,500</span>
              </div>
              <span class="plan-subtitle">年繳優惠 6,750/月
              </span>
              <ul class="plan-feature">
                <li><feather-icon
                  icon="CheckIcon"
                  class="text-primary mr-3"
                  stroke-width="4"
                  size="16"
                /> 現金貸款</li>
                <li><feather-icon
                  icon="CheckIcon"
                  class="text-primary mr-3"
                  stroke-width="4"
                  size="16"
                /> 顧客管理系統</li>
                <li><feather-icon
                  icon="CheckIcon"
                  class="text-primary mr-3"
                  stroke-width="4"
                  size="16"
                /> 店舖經營分析系統</li>
                <li><feather-icon
                  icon="CheckIcon"
                  class="text-primary mr-3"
                  stroke-width="4"
                  size="16"
                /> 軟體資訊安全</li>
                <li><feather-icon
                  icon="CheckIcon"
                  class="text-primary mr-3"
                  stroke-width="4"
                  size="16"
                /> 允許或限制IP位置範圍</li>
                <li><feather-icon
                  icon="CheckIcon"
                  class="text-primary mr-3"
                  stroke-width="4"
                  size="16"
                /> 單間店</li>
                <li><feather-icon
                  icon="CheckIcon"
                  class="text-primary mr-3"
                  stroke-width="4"
                  size="16"
                /> 擔保品貸款</li>
              </ul>
              <!-- <a href="#" class="pricing-btn">Select Plan <i class="fas fa-arrow-right"></i></a> -->
              <span class="plan-tag">最優惠</span>
            </div>
          </div>
          <div
            class="col-lg-4 col-md-6 col-sm-10 wow fadeInUp"
            data-wow-delay="0.4s"
          >
            <div class="pricing-table-three m-t-30">
              <h6 class="plan-name">
                企業方案
              </h6>
              <div class="price">
                <span class="currency">$</span>
                <span>洽詢</span>
              </div>
              <span class="plan-subtitle">連鎖集團優惠</span>
              <ul class="plan-feature">
                <li><feather-icon
                  icon="CheckIcon"
                  class="text-primary mr-3"
                  stroke-width="4"
                  size="16"
                /> 現金貸款</li>
                <li><feather-icon
                  icon="CheckIcon"
                  class="text-primary mr-3"
                  stroke-width="4"
                  size="16"
                /> 顧客管理系統</li>
                <li><feather-icon
                  icon="CheckIcon"
                  class="text-primary mr-3"
                  stroke-width="4"
                  size="16"
                /> 店舖經營分析系統</li>
                <li><feather-icon
                  icon="CheckIcon"
                  class="text-primary mr-3"
                  stroke-width="4"
                  size="16"
                /> 軟體資訊安全</li>
                <li><feather-icon
                  icon="CheckIcon"
                  class="text-primary mr-3"
                  stroke-width="4"
                  size="16"
                /> 允許或限制IP位置範圍</li>
                <li><feather-icon
                  icon="CheckIcon"
                  class="text-primary mr-3"
                  stroke-width="4"
                  size="16"
                /> 多間店</li>
                <li><feather-icon
                  icon="CheckIcon"
                  class="text-primary mr-3"
                  stroke-width="4"
                  size="16"
                /> 擔保品貸款</li>
              </ul>
              <!-- <a href="#" class="pricing-btn">Select Plan <i class="fas fa-arrow-right"></i></a> -->
            </div>
          </div>
        </div>
        <div class="row p-t-10">
          <div class="col">
            <h5>＊所有方案免費測試 1 週＊</h5>
            <h5>＊半年繳用戶酌收工本費 1,500元＊</h5>
            <h5>＊每多增加一位員工帳號100/月＊</h5>
            <h5>＊獨自私人資料庫設定與客製化 需另外報價＊</h5>
          </div>
        </div>
      </div>
    </section>
    <!--====== Pricing Area End ======-->

    <!--====== Start FAQ section ======-->
    <section class="fag-section p-t-130 p-b-130">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-10">
            <div class="common-heading-2 text-center m-b-50">
              <div class="common-heading tagline-boxed title-line m-b-50 text-center">
                <span class="tagline">常見問答集</span>
                <h2 class="title">
                  購買前，請在這裡找到你想要的答案。 <span>FAQ <b-img
                    src="@/assets/images/landing/section-title-v2-line-yellow.png"
                    alt="Line"
                  /></span>
                </h2>
              </div>
            </div>
            <div>
              <b-tabs align="center">
                <b-tab
                  title="一般"
                  class="pr-2"
                  active
                  @click="faqTab = 'uses'"
                />
                <b-tab
                  title="費用"
                  @click="faqTab = 'pricing'"
                />
                <b-tab
                  title="客服"
                  @click="faqTab = 'customerService'"
                />
                <b-tab
                  title="使用"
                  @click="faqTab = 'uses'"
                />
              </b-tabs>
            </div>
            <div class="accordion-tab">
              <faq-question-answer
                :options="dataByType(faqTab)"
                :title="faqTitle[faqTab]"
              />
            </div>
            <!-- <div class="accordion-tab">
              <ul
                id="accordionFAQTab"
                class="nav nav-tabs"
                role="tablist"
              >
                <li class="nav-item">
                  <a
                    class="nav-link active"
                    data-toggle="tab"
                    href="#general"
                    role="tab"
                  >一般</a>
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    data-toggle="tab"
                    href="#pricing"
                    role="tab"
                  >費用</a>
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    data-toggle="tab"
                    href="#support"
                    role="tab"
                  >客服</a>
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    data-toggle="tab"
                    href="#operate"
                    role="tab"
                  >操作</a>
                </li>
              </ul>
              <div
                id="accordionFAQTabContent"
                class="tab-content"
              >
                <div
                  id="general"
                  class="tab-pane fade show active"
                  role="tabpanel"
                >
                  <div class="landio-accordion-v2">
                    <div
                      id="generalFAQ"
                      class="accordion"
                    >
                      <div class="accordion-item">
                        <h5
                          id="headingOne1"
                          class="accordion-header"
                        >
                          <button
                            class="accordion-button"
                            type="button"
                            data-toggle="collapse"
                            data-target="#collapseOne1"
                            aria-expanded="true"
                            aria-controls="collapseOne1"
                          >
                            請問BEESUMMER支援哪些借貸方式呢？
                          </button>
                        </h5>
                        <div
                          id="collapseOne1"
                          class="collapse show"
                          aria-labelledby="headingOne1"
                          data-parent="#generalFAQ"
                        >
                          <div class="accordion-body">
                            <ul>
                              <li><i class="fas fa-check color-primary-6" /> 本金到期一次清償法；全部貸款本金於到期時一次償付，利息則按貸款餘額逐月計算</li>
                              <li><feather-icon
                                icon="CheckIcon"
                                class="text-primary mr-3"
                                stroke-width="4"
                                size="16"
                              /> 本息平均攤還法將與抵押品一起釋出</li>
                              <li class="hidden-feature">
                                <feather-icon
                                  icon="CheckIcon"
                                  class="text-primary mr-3"
                                  stroke-width="4"
                                  size="16"
                                /> 本金平均攤還法、有寬限期攤還法，會依照需求推出
                              </li>
                            </ul>

                          </div>
                        </div>
                      </div>
                      <div class="accordion-item">
                        <h5
                          id="headingTwo1"
                          class="accordion-header"
                        >
                          <button
                            class="accordion-button collapsed"
                            type="button"
                            data-toggle="collapse"
                            data-target="#collapseTwo1"
                            aria-expanded="false"
                            aria-controls="collapseTwo1"
                          >
                            本來的借貸文件或是舊有軟體的顧客與借貸紀錄，該怎麼導入你們的系統中？
                          </button>
                        </h5>
                        <div
                          id="collapseTwo1"
                          class="collapse"
                          aria-labelledby="headingTwo1"
                          data-parent="#generalFAQ"
                        >
                          <div class="accordion-body">
                            <p>購買軟體後，若有導入舊資料需求</p>
                            <p>步驟 1：導入評估（依照量和資料結構做估價）</p>
                            <p>步驟 2：工程團隊進行程式撰寫，並開始導入工作</p>
                            <p>步驟 3：導入完成後，顧客需要確認資料正確性</p>
                          </div>
                        </div>
                      </div>
                      <div class="accordion-item">
                        <h5
                          id="headingThree1"
                          class="accordion-header"
                        >
                          <button
                            class="accordion-button collapsed"
                            type="button"
                            data-toggle="collapse"
                            data-target="#collapseThree1"
                            aria-expanded="false"
                            aria-controls="collapseThree1"
                          >
                            我們公司的電腦是比較舊的系統也可以使用嗎？
                          </button>
                        </h5>
                        <div
                          id="collapseThree1"
                          class="collapse"
                          aria-labelledby="headingThree1"
                          data-parent="#generalFAQ"
                        >
                          <div class="accordion-body">
                            <p>網頁雲端系統的優勢就是系統需要的資源比較低，任何電腦只要能開啟遊覽器都能直接使用，使用預覽器輸入beesummer.com的網址後，即可在手機、平板、電腦中使用，且微軟、蘋果、安卓系統皆可使用。</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  id="pricing"
                  class="tab-pane fade"
                  role="tabpanel"
                >
                  <div class="landio-accordion-v2">
                    <div
                      id="pricingFAQ"
                      class="accordion"
                    >
                      <div class="accordion-item">
                        <h5
                          id="headingFour3"
                          class="accordion-header"
                        >
                          <button
                            class="accordion-button collapsed"
                            type="button"
                            data-toggle="collapse"
                            data-target="#collapseFour3"
                            aria-expanded="false"
                            aria-controls="collapseFour3"
                          >
                            如何購買？付款流程為何？
                          </button>
                        </h5>
                        <div
                          id="collapseFour3"
                          class="collapse"
                          aria-labelledby="headingFour3"
                          data-parent="#pricingFAQ"
                        >
                          <div class="accordion-body">
                            <p>購買流程為</p>
                            <p>步驟1: 顧客轉帳、開立發票。</p>
                            <p>步驟2: 借貸系統開通。</p>
                            <p>步驟3: 開始使用軟體。</p>
                          </div>
                        </div>
                      </div>
                      <div class="accordion-item">
                        <h5
                          id="headingOne3"
                          class="accordion-header"
                        >
                          <button
                            class="accordion-button"
                            type="button"
                            data-toggle="collapse"
                            data-target="#collapseOne3"
                            aria-expanded="true"
                            aria-controls="collapseOne3"
                          >
                            可以解約、退款嗎？
                          </button>
                        </h5>
                        <div
                          id="collapseOne3"
                          class="collapse show"
                          aria-labelledby="headingOne3"
                          data-parent="#pricingFAQ"
                        >
                          <div class="accordion-body">
                            <p>可以隨時解約、退款，軟體使用期限依照月來計算，在 12/20 簽約至隔年 12/20，在 5/30 提出停用退款，軟體使用期限會在6/19停止。</p>
                            <p>退款金額計算，年繳用戶提早解約會改以半年繳的價格計算，扣除已使用的軟體時間，及 1,500 工本費的剩餘金額。</p>
                          </div>
                        </div>
                      </div>
                      <div class="accordion-item">
                        <h5
                          id="headingTwo3"
                          class="accordion-header"
                        >
                          <button
                            class="accordion-button collapsed"
                            type="button"
                            data-toggle="collapse"
                            data-target="#collapseTwo3"
                            aria-expanded="false"
                            aria-controls="collapseTwo3"
                          >
                            忘了付款，資料是否會被刪除？
                          </button>
                        </h5>
                        <div
                          id="collapseTwo3"
                          class="collapse"
                          aria-labelledby="headingTwo3"
                          data-parent="#pricingFAQ"
                        >
                          <div class="accordion-body">
                            <p>忘了付款資料並不會被刪除，但會有操作限制，只能讀取特定資料，不能單獨新增或刪除任何資料。</p>
                          </div>
                        </div>
                      </div>
                      <div class="accordion-item">
                        <h5
                          id="headingThree3"
                          class="accordion-header"
                        >
                          <button
                            class="accordion-button collapsed"
                            type="button"
                            data-toggle="collapse"
                            data-target="#collapseThree3"
                            aria-expanded="false"
                            aria-controls="collapseThree3"
                          >
                            解約後想回來繼續使用，會有什麼額外費用嗎？
                          </button>
                        </h5>
                        <div
                          id="collapseThree3"
                          class="collapse"
                          aria-labelledby="headingThree3"
                          data-parent="#pricingFAQ"
                        >
                          <div class="accordion-body">
                            <p>回歸的客戶收費依照標準收費，並不會有額外費用，過去的資料也將可繼續使用。</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  id="support"
                  class="tab-pane fade"
                  role="tabpanel"
                >
                  <div class="landio-accordion-v2">
                    <div
                      id="supportFAQ"
                      class="accordion"
                    >
                      <div class="accordion-item">
                        <h5
                          id="headingOne4"
                          class="accordion-header"
                        >
                          <button
                            class="accordion-button"
                            type="button"
                            data-toggle="collapse"
                            data-target="#collapseOne4"
                            aria-expanded="true"
                            aria-controls="collapseOne4"
                          >
                            客服的聯絡方式？
                          </button>
                        </h5>
                        <div
                          id="collapseOne4"
                          class="collapse show"
                          aria-labelledby="headingOne4"
                          data-parent="#supportFAQ"
                        >
                          <div class="accordion-body">
                            <p>我們提供 LINE 及 Telegram，在營業時間週一至週五 10:00 - 19:00 提供服務。</p>
                            <p><a href="https://beesummer.com/links">快選單</a></p>
                          </div>
                        </div>
                      </div>
                      <div class="accordion-item">
                        <h5
                          id="headingTwo4"
                          class="accordion-header"
                        >
                          <button
                            class="accordion-button collapsed"
                            type="button"
                            data-toggle="collapse"
                            data-target="#collapseTwo4"
                            aria-expanded="false"
                            aria-controls="collapseTwo4"
                          >
                            出現系統錯誤、功能需求或客制化需求該如何提出？
                          </button>
                        </h5>
                        <div
                          id="collapseTwo4"
                          class="collapse"
                          aria-labelledby="headingTwo4"
                          data-parent="#supportFAQ"
                        >
                          <div class="accordion-body">
                            <p>可以主動聯絡客服或使用提報表格 <a href="https://beesummer.com/links">快選單</a> 提出相關問題及需求。</p>
                            <p>不是很急又符合系統走向的需求，我們會加入開發排成，功能會在未來版本免費出現。</p>
                            <p>客制化需求，則需要依照功能討論及報價。</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  id="operate"
                  class="tab-pane fade"
                  role="tabpanel"
                >
                  <div class="landio-accordion-v2">
                    <div
                      id="operateFAQ"
                      class="accordion"
                    >
                      <div class="accordion-item">
                        <h5
                          id="headingOne6"
                          class="accordion-header"
                        >
                          <button
                            class="accordion-button"
                            type="button"
                            data-toggle="collapse"
                            data-target="#collapseOne6"
                            aria-expanded="true"
                            aria-controls="collapseOne6"
                          >
                            如何刪除商店資料
                          </button>
                        </h5>
                        <div
                          id="collapseOne6"
                          class="collapse show"
                          aria-labelledby="headingOne6"
                          data-parent="#operateFAQ"
                        >
                          <div class="accordion-body">
                            <p>在帳號人物頭像上點選，設定後，選擇資安，刪除資料，輸入刪除後資料將完整刪除。</p>
                            <p>若要解約或不再使用，可考慮執行刪除。</p>
                          </div>
                        </div>
                      </div>
                      <div class="accordion-item">
                        <h5
                          id="headingTwo6"
                          class="accordion-header"
                        >
                          <button
                            class="accordion-button collapsed"
                            type="button"
                            data-toggle="collapse"
                            data-target="#collapseTwo6"
                            aria-expanded="false"
                            aria-controls="collapseTwo6"
                          >
                            如何刪除顧客？
                          </button>
                        </h5>
                        <div
                          id="collapseTwo6"
                          class="collapse"
                          aria-labelledby="headingTwo6"
                          data-parent="#operateFAQ"
                        >
                          <div class="accordion-body">
                            <p>點入顧客頁面，在上方數據處點選右鍵選擇刪除即可。請注意刪除的顧客資料將無法恢復。</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> -->
          </div>
        </div>
      </div>
    </section>
    <!--====== End FAQ section ======-->

    <!--====== Start CTA Section ======-->
    <section class="cta-section cta-with-particle border-bottom-primary p-t-130 p-b-100">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-6">
            <div class="cta-content wow fadeInUp">
              <div class="common-heading tagline-boxed m-b-50 text-center">
                <h2 class="title">
                  註冊帳號後，申請開通商店即可開始使用
                </h2>
              </div>
              <ul class="cta-btns justify-content-center">
                <li>
                  <a
                    href="/links"
                    class="template-btn"
                  >
                    聯絡客服 <feather-icon
                      icon="ArrowRightIcon"
                      class="ml-2"
                      stroke-width="4"
                      size="16"
                    />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="cta-particle d-none d-md-block">
          <b-img
            class="particle-left"
            src="@/assets/images/landing/cta-left-particle-1.png"
            alt="cta particle 1"
          />
          <b-img
            class="particle-right"
            src="@/assets/images/landing/cta-right-particle-1.png"
            alt="cta particle 2"
          />
        </div>
      </div>
    </section>
    <!--====== End CTA Section ======-->

    <!--====== Start Footer ======-->
    <footer class="template-footer footer-bordered p-t-10">
      <div class="container">
        <div class="footer-widgets">
          <div class="row">
            <div class="col-lg-5 col-md-10">
              <div class="footer-widget-left">
                <!-- Single Footer Widget -->
                <div class="widget text-block-widget">
                  <b-img
                    src="@/assets/images/logo/logo-white.png"
                    alt="footer logo"
                  />
                  <p>適合用於小額週轉、預借現金、小額融資、小額放貸、買車換現等現金貸款業務。</p>

                  <a
                    class="contact-link"
                    href="tel:+88623467172"
                  ><feather-icon
                    icon="PhoneIcon"
                    class="text-primary mr-1"
                    stroke-width="4"
                    size="16"
                  /> +886 23467172</a>
                </div>
              </div>
            </div>
            <div class="col-lg-7">
              <div class="row footer-widget-right">
                <!-- Single Footer Widget -->
                <div class="col-lg-4 col-sm-6">
                  <!-- <div class="d-lg-flex justify-content-start">
                                    <div class="widget nav-widget">
                                        <h5 class="widget-title">Resources</h5>
                                        <ul>
                                            <li><a href="about.html">Development</a></li>
                                            <li><a href="about.html">Our Products</a></li>
                                            <li><a href="about.html">User Strategy</a></li>
                                            <li><a href="about.html">Blogs &amp; Guides</a></li>
                                            <li><a href="about.html">Premium Support</a></li>
                                        </ul>
                                    </div>
                                </div> -->
                </div>
                <!-- Single Footer Widget -->
                <div class="col-lg-4 col-sm-6">
                  <!-- <div class="d-lg-flex justify-content-center">
                                    <div class="widget nav-widget">
                                        <h5 class="widget-title">Company</h5>
                                        <ul>
                                            <li><a href="about.html">About Landio</a></li>
                                            <li><a href="about.html">Contact</a></li>
                                            <li><a href="services.html">Success History</a></li>
                                            <li><a href="services.html">Setting &amp; Privacy</a></li>
                                            <li><a href="services.html">Company History</a></li>
                                        </ul>
                                    </div>
                                </div> -->
                </div>
                <!-- Single Footer Widget -->
                <div class="col-lg-4 col-sm-6">
                  <div class="d-lg-flex justify-content-end">
                    <div class="widget nav-widget">
                      <h5 class="widget-title">
                        Support
                      </h5>
                      <ul>
                        <li><a href="#"><feather-icon
                          icon="ChevronRightIcon"
                          class="mr-2"
                          stroke-width="3"
                          size="18"
                        />隱私條款</a></li>
                        <li><a href="#"><feather-icon
                          icon="ChevronRightIcon"
                          class="mr-2"
                          stroke-width="3"
                          size="18"
                        />使用條款</a></li>
                        <li><b-link :to="{ name: 'links' }"><feather-icon
                          icon="ChevronRightIcon"
                          class="mr-2"
                          stroke-width="3"
                          size="18"
                        />快速連結</b-link></li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="footer-copyright">
        <div class="container">
          <div class="row align-items-center justify-content-between">
            <div class="col-sm-auto col-12 order-lg-last">
              <ul class="social-links">
                <li><a href="https://t.me/joinchat/b2sMe0PZDCZiOGFl"><b-img
                  src="@/assets/images/icons/telegram.svg"
                  width="20px"
                  :fill="'#fbc614'"
                /></a></li>
                <li><a href="https://lin.ee/9j8f6jh?fbclid=IwAR1heZuxhzutBxo3LqOf3u58I2CjEfoqtfDayuDGtE5m9rofVksdtLoj0IQ"><b-img
                  src="@/assets/images/icons/line.svg"
                  width="20px"
                  class="text-primary"
                /></a></li>
              </ul>
            </div>
            <div class="col-sm-auto col-12">
              <p class="copyright-text text-center text-sm-right pt-4 pt-sm-0">
                © 2021 <a href="#">BEE<span class="text-white">SUMMER</span></a> All Rights Reserved
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
    <!--====== End Footer ======-->

  </body>
</template>

<script>
/* eslint-disable global-require */
import {
  BLink, BImg, BTabs, BTab, BSidebar, VBToggle, BListGroup, BListGroupItem,
} from 'bootstrap-vue'

import '@/assets/static/css/bootstrap.min.css'
import '@/assets/static/css/slick.min.css'
import '@/assets/static/css/magnific-popup.min.css'
import '@/assets/static/css/nice-select.min.css'
import '@/assets/static/css/animate.min.css'
import '@/assets/static/css/spacing.min.css'
import '@/assets/static/css/style.css'
import '@/assets/static/css/responsive.css'

import FaqQuestionAnswer from '@/views/faq/FaqQuestionAnswer.vue'

export default {
  components: {
    BImg,
    BTabs,
    BLink,
    BTab,
    BSidebar,
    BListGroup,
    BListGroupItem,
    FaqQuestionAnswer,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  data() {
    return {
      sidebarItems: [
        { title: '功能', icon: 'GridIcon', url: '#service-tab-section' },
        { title: '價格', icon: 'ActivityIcon', url: '#pricing-section' },
        { title: '聯絡我們', icon: 'RefreshCwIcon', url: '/links' },
        { title: '登入', icon: 'SettingsIcon', url: '/login' },
        { title: '註冊', icon: 'UserIcon', url: '/signup' },
      ],
      tabImage: require('@/assets/images/landing/service-tab-img.png'),
      tabActive: 'service-tab-img',
      tabImageList: {
        'service-tab-img': require('@/assets/images/landing/service-tab-img.png'),
        'service-tab-img2': require('@/assets/images/landing/service-tab-img2.png'),
        'service-tab-img3': require('@/assets/images/landing/service-tab-img3.png'),
        'service-tab-img4': require('@/assets/images/landing/service-tab-img4.png'),
        'service-tab-img5': require('@/assets/images/landing/service-tab-img5.png'),
        'service-tab-img6': require('@/assets/images/landing/service-tab-img6.png'),
        'service-tab-img7': require('@/assets/images/landing/service-tab-img7.png'),
        'service-tab-img8': require('@/assets/images/landing/service-tab-img8.png'),
      },
      faqTab: 'normal',
      faqTitle: {
        normal: {
          key: 'normal',
        },
        pricing: {
          key: 'pricing',
        },
        customerService: {
          key: 'customerService',
        },
        uses: {
          key: 'uses',
        },
      },
      qaData: [
        {
          key: 'normal',
          question: '請問BEESUMMER支援哪些借貸方式呢？',
          ans: `<ul>
                              <li><i class="fas fa-check color-primary-6" /> 本金到期一次清償法；全部貸款本金於到期時一次償付，利息則按貸款餘額逐月計算</li>
                              <li><feather-icon
                                icon="CheckIcon"
                                class="text-primary mr-3"
                                stroke-width="4"
                                size="16"
                              /> 本息平均攤還法將與抵押品一起釋出</li>
                              <li class="hidden-feature">
                                <feather-icon
                                  icon="CheckIcon"
                                  class="text-primary mr-3"
                                  stroke-width="4"
                                  size="16"
                                /> 本金平均攤還法、有寬限期攤還法，會依照需求推出
                              </li>
                            </ul>`,
        },
        {
          key: 'normal',
          question: '本來的借貸文件或是舊有軟體的顧客與借貸紀錄，該怎麼導入你們的系統中？',
          ans: `<p>購買軟體後，若有導入舊資料需求</p>
                            <p>步驟 1：導入評估（依照量和資料結構做估價）</p>
                            <p>步驟 2：工程團隊進行程式撰寫，並開始導入工作</p>
                            <p>步驟 3：導入完成後，顧客需要確認資料正確性</p>`,
        },
        {
          key: 'normal',
          question: '我們公司的電腦是比較舊的系統也可以使用嗎？',
          ans: '網頁雲端系統的優勢就是系統需要的資源比較低，任何電腦只要能開啟遊覽器都能直接使用，使用預覽器輸入beesummer.com的網址後，即可在手機、平板、電腦中使用，且微軟、蘋果、安卓系統皆可使用。',
        },
        {
          key: 'pricing',
          question: '如何購買？付款流程為何？',
          ans: `<p>購買流程為</p>
                            <p>步驟1: 顧客轉帳、開立發票。</p>
                            <p>步驟2: 借貸系統開通。</p>
                            <p>步驟3: 開始使用軟體。</p>`,
        },
        {
          key: 'pricing',
          question: '可以解約、退款嗎？',
          ans: `<p>可以隨時解約、退款，軟體使用期限依照月來計算，在 12/20 簽約至隔年 12/20，在 5/30 提出停用退款，軟體使用期限會在6/19停止。</p>
                            <p>退款金額計算，年繳用戶提早解約會改以半年繳的價格計算，扣除已使用的軟體時間，及 1,500 工本費的剩餘金額。</p>`,
        },
        {
          key: 'pricing',
          question: '忘了付款，資料是否會被刪除？',
          ans: '忘了付款資料並不會被刪除，但會有操作限制，只能讀取特定資料，不能單獨新增或刪除任何資料。',
        },
        {
          key: 'customerService',
          question: '客服的聯絡方式？',
          ans: `<p>我們提供 LINE 及 Telegram，在營業時間週一至週五 10:00 - 19:00 提供服務。</p>
                            <p><a href="https://beesummer.com/links">快選單</a></p>`,
        },
        {
          key: 'customerService',
          question: '出現系統錯誤、功能需求或客制化需求該如何提出？',
          ans: `<p>可以主動聯絡客服或使用提報表格 <a href="https://beesummer.com/links">快選單</a> 提出相關問題及需求。</p>
                            <p>不是很急又符合系統走向的需求，我們會加入開發排成，功能會在未來版本免費出現。</p>
                            <p>客制化需求，則需要依照功能討論及報價。</p>
                          </div>`,
        },
        {
          key: 'uses',
          question: '如何刪除商店資料',
          ans: `<p>在帳號人物頭像上點選，設定後，選擇資安，刪除資料，輸入刪除後資料將完整刪除。</p>
                            <p>若要解約或不再使用，可考慮執行刪除。</p>`,
        },
        {
          key: 'uses',
          question: '如何刪除顧客？',
          ans: '點入顧客頁面，在上方數據處點選右鍵選擇刪除即可。請注意刪除的顧客資料將無法恢復。',
        },
      ],
    }
  },
  computed: {
    faqTabs() {
      return this.qaData.map(item => item.key).filter((value, index, self) => self.indexOf(value) === index)
    },
  },
  methods: {
    dataByType(type) {
      return this.qaData.filter(el => el.key === type)
    },
  },
}
</script>

<style lang="scss">
  .nav-tabs .nav-link {
    padding: 1px, 1px;
  }
  .nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
    border: none;
  }
  .dark-layout .nav-tabs .nav-item .nav-link.active {
    background-color: #FBC614;
    color: black;
    border-radius: 5px;
  }
  .nav-tabs {
    border: none;
  }
  .nav-tabs .nav-link:hover {
    border: none;
    color: black;
    background-color: #FBC614;
    border-radius: 5px;
    margin: 0;
    padding: 8px 16px;
  }
</style>
